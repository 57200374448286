// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-outcome-jsx": () => import("./../../../src/pages/Outcome.jsx" /* webpackChunkName: "component---src-pages-outcome-jsx" */),
  "component---src-pages-step-01-jsx": () => import("./../../../src/pages/Step01.jsx" /* webpackChunkName: "component---src-pages-step-01-jsx" */),
  "component---src-pages-step-02-jsx": () => import("./../../../src/pages/Step02.jsx" /* webpackChunkName: "component---src-pages-step-02-jsx" */),
  "component---src-pages-step-03-jsx": () => import("./../../../src/pages/Step03.jsx" /* webpackChunkName: "component---src-pages-step-03-jsx" */),
  "component---src-pages-step-04-jsx": () => import("./../../../src/pages/Step04.jsx" /* webpackChunkName: "component---src-pages-step-04-jsx" */)
}

